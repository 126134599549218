import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const ImageWrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

const HeaderLogo = () => {
  const data = useStaticQuery(
    graphql`
      query HeaderLogoImageQuery {
        headerImage: file(relativePath: { eq: "header/img_main.PNG" }) {
          childImageSharp {
            fluid(maxHeight: 360, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <ImageWrapper className="header-image__wrapper">
      <Link to='/'>
        <Img fluid={data.headerImage.childImageSharp.fluid} alt="website header logo with planet pea text" />
      </Link>
    </ImageWrapper>
  )
}

export default HeaderLogo;
